import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
import Bot from '../../templates/BotPlatform/pages/Bot';
const host = process.env.GATSBY_HOST;

const viber = {
  header: {
    subTitle: 'botPlatformViberTitle',
    text: 'botPlatformViberSubTitle',
    logo: 'bot_viber@2x.png',
    button: {
      text: 'botPlatformViberButtonText',
      link: `${host}/auth2/single_account?redirect_uri=${host}/&enter=signup`
    }
  },
  list: [
    {
      key: 'keyboard',
      title: 'botPlatformViberKeyboardTitle',
      subTitle: 'botPlatformViberKeyboardSubTitle',
      image: 'viber_keyboard.png',
    },
    {
      key: 'slider',
      title: 'botPlatformViberSliderTitle',
      subTitle: 'botPlatformViberSliderSubTitle',
      image: 'viber_carousel.png',
    },
    {
      key: 'contactCenter',
      title: 'botPlatformViberContactCenterTitle',
      subTitle: 'botPlatformViberContactCenterSubTitle',
      image: 'viber_contact-center.png',
    },
    {
      key: 'bulkMailing',
      title: 'botPlatformViberBulkMailingTitle',
      subTitle: 'botPlatformViberBulkMailingSubTitle',
      image: 'viber_subscribels.png',
    },
    {
      key: 'sharingPhone',
      title: 'botPlatformViberSharingPhoneTitle',
      subTitle: 'botPlatformViberSharingPhoneSubTitle',
      image: 'viber_phone-share.png',
    },
    {
      key: 'sharingLocation',
      title: 'botPlatformViberSharingLocationTitle',
      subTitle: 'botPlatformViberSharingLocationSubTitle',
      image: 'viber_share-location.png',
    },
    {
      key: 'richMedia',
      title: 'botPlatformViberRichMediaTitle',
      subTitle: 'botPlatformViberRichMediaSubTitle',
      image: 'viber_rich-media.png',
    },
    {
      key: 'stikers',
      title: 'botPlatformViberStikersTitle',
      subTitle: 'botPlatformViberStikersSubTitle',
      image: 'viber_stikers.png',
    },
    {
      key: 'deepLink',
      title: 'botPlatformViberDeepLinkTitle',
      subTitle: 'botPlatformViberDeepLinkSubTitle',
      linkTitle: 'botPlatformViberDeepLinkLink',
      image: 'viber_deep-link.png',
    },
    {
      key: 'payments',
      title: 'botPlatformViberPaymentsTitle',
      subTitle: 'botPlatformViberPaymentsSubTitle',
      image: 'viber-payments2x.png',
    },
  ],
  enterprise: {
    title: 'botPlatformEnterpriseTitle',
    list: [
      {
        key: 'security',
        title: 'botPlatformEnterpriseSecurityTitle',
        subTitle: 'botPlatformEnterpriseSecuritySubTitle',
        image: 'security.png',
      },
      {
        key: 'performance',
        title: 'botPlatformEnterprisePerformanceTitle',
        subTitle: 'botPlatformEnterprisePerformanceSubTitle',
        image: 'performance.png',
      },
      {
        key: 'scale',
        title: 'botPlatformEnterpriseScaleTitle',
        subTitle: 'botPlatformEnterpriseScaleSubTitle',
        image: 'scale.png',
      }
    ]
  },
  clients: {
    title: 'botPlatformViberClientsTitle',
    list: [
      {key: 'portmone', image: 'portmone.png'},
      {key: 'iqos', image: 'iqos.png',},
      {key: 'varus', image: 'varus.png'},
      {key: 'metro', image: 'metro.png'},
      {key: 'novaPoshta', image: 'nova-poshta.png'},
      {key: 'comfy', image: 'comfy.png'},
      {key: 'pumb', image: 'pumb.png'},
      {key: 'jooble', image: 'jooble.png'},
    ]
  },
  form: {
    type: 'Viber',
    text: 'botPlatformViberFormTitle',
    phoneLeft: 'viber-phone-left.png',
    phoneRight: 'viber-phone-right.png',
  }
};

const ViberPage = (props) => (
  <Layout page={'bot-platform'} {...props}>
    <Bot
      content={viber}
      type={'viber'}
      data={props.data}
      {...props}
    />
  </Layout>
);

ViberPage.propTypes = {
  data: PropTypes.object
};

export default withIntl(ViberPage);
